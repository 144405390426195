import { createApp } from 'vue';

// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import AsImage from '@awesome-image/image';

import { imageUrlGeneratorFP } from '@awesome-image/services';
import { ObserveVisibility } from 'vue-observe-visibility';
import VueGoogleMaps from '@fawmi/vue-google-maps';
// eslint-disable-next-line import/no-extraneous-dependencies
import { VueQueryPlugin } from 'vue-query';
import VueAxios from 'vue-axios';

import FlagIcon from 'vue-flag-icon';
import CONFIG from '@/services/config';

import App from './App.vue';
import router from './router';
import store from './store';
import apiAxios from './services/apiAxios';

import 'ant-design-vue/dist/antd.variable.min.css';
import '@/assets/styles/tailwind.css';
import '@/assets/styles/main.css';
import '@awesome-image/image/dist/style.css';

const app = createApp(App);

app.directive('observe-visibility', ObserveVisibility);

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: 1000 * 60 * 10, // 10 minutes
      },
    },
  },
};

app
  .use(AsImage, {
    responsive: true,
    progressive: true,
    imageUrlGenerator: imageUrlGeneratorFP,
  })
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(FlagIcon)
  .use(store)
  .use(router);

app.use(VueGoogleMaps, {
  load: {
    key: CONFIG.MAPS_KEY,
    // language: 'de',
  },
  autobindAllEvents: true,
});

app.use(VueAxios, apiAxios);
app.provide('axios', app.config.globalProperties.axios); // provide 'axios'

app.mount('#app');
