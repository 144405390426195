<template>
  <div class="h-full flex flex-col gap-4">
    <div class="flex-1">
      <GoogleMap
        ref="map"
        :api-key="API_KEY"
        style="width: 100%; height: 500px"
        :center="center"
        :zoom="zoom"
        v-on:zoom_changed="geolocation()"
        v-on:center_changed="geolocation()"
        v-on:idle="geolocation()"
      >
        <div v-for="Imap in mapData" :key="Imap.id">
          <CustomMarker
            CustomMarker
            :options="{
              position: { lat: Imap.lat, lng: Imap.lng },
              anchorPoint: 'BOTTOM_CENTER',
            }"
          >
            <div class="relative">
              <!-- <div
                class="flex gap-3 items-center justify-center bg-bg-light p-3 rounded-lg shadow-2xl absolute -top-[4rem]"
              >
                <PhUser weight="duotone" size="18" />
                <div class="flex flex-col gap-1">
                  <strong> 6 Visits </strong>
                </div>
              </div> -->

              <div class="flex flex-col gap-2 items-center main-card p-3">
                <PhBuildings weight="duotone" size="26" />
                <h3>{{ Imap.name }}</h3>
                <strong class="flex items-center gap-1">
                  <PhUser weight="duotone" size="18" /> {{ Imap.visits }}</strong
                >
              </div>
            </div>
          </CustomMarker>
        </div>
      </GoogleMap>
    </div>

    <FetchMapQuery
      :key="center.lat + '-' + center.lng + '-' + zoom"
      :lat="center.lat"
      :lng="center.lng"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { GoogleMap, CustomMarker } from 'vue3-google-map';
import CONFIG from '@/services/config';
import { PhBuildings, PhUser } from 'phosphor-vue';
import FetchMapQuery from '@/services/queries/dash/FetchMapQuery.vue';
import debounce from 'lodash.debounce';

export default {
  components: { GoogleMap, CustomMarker, PhBuildings, PhUser, FetchMapQuery },
  data() {
    return {
      API_KEY: CONFIG.MAPS_KEY,
      center: { lat: 29.256546817907363, lng: 47.9202705465268 },
      zoom: 11, // 15
      mapData: [],
    };
  },

  computed: {
    mapsQuery() {
      return this.$store.state.dash.mapsQuery;
    },
    mapQueryData() {
      return this.mapsQuery?.data?.data;
    },
  },
  watch: {
    mapQueryData: {
      handler(val) {
        if (typeof val !== 'undefined') {
          this.mapData = val;
        }
      },
      deep: true,
    },
  },
  methods: {
    async geolocation() {
      this.debounceGeo(this);
    },
    debounceGeo: debounce((_self) => {
      const currentMap = _self.$refs?.map?.map;
      if (!currentMap) return;
      const lat = currentMap.center.lat();
      const lng = currentMap.center.lng();
      const { zoom } = currentMap;

      // eslint-disable-next-line no-param-reassign
      _self.center = { lat, lng };
      // eslint-disable-next-line no-param-reassign
      _self.zoom = zoom;
    }, 400),
  },
  created() {},
  mounted() {},
};
</script>
