<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="touched && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
  >
    <InputPassword
      :value="value"
      :placeholder="placeholder"
      @change="handleChange"
      @blur="handleBlur"
    />
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import { InputPassword } from 'ant-design-vue';
import CustumLabel from './CustumLabel.vue';

export default {
  components: { CustumLabel, InputPassword },
  props: {
    name: String,
    label: String,
    placeholder: String,
    hideErrors: Boolean,
    defaultData: String,
    defaultError: String,
    password: String,
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: null,
      error: null,
      touched: false,
      isValid: false,
    };
  },
  created() {
    if (this.defaultData) {
      this.value = this.defaultData;
      this.touched = true;
    }
    this.handleValidate();
    this.setInputValues();
  },
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
    defaultError(val) {
      if (val) {
        this.touched = true;
        this.error = val[0].length > 1 ? val[0] : val;
      }
    },
  },
  methods: {
    handleBlur() {
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },
    handleChange(e) {
      const val = e.target.value ? e.target.value.trim() : null;
      this.error = null;
      this.value = val;
    },
    handleValidate() {
      const extraPassword = this.password ? { password: this.password } : null;

      const { error, isValid } = validation.validateRules(this.rules, this.value, extraPassword);
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && !this.value) {
        return;
      }
      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: this.value,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
  },
};
</script>
