<template>
  <div class="w-full border-t border-gray-100 border-solid border-0 mt-4 pt-3">
    <Table :dataSource="currentVisits" :columns="visitColumns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'select'"> <Checkbox /> </template>

        <template v-else-if="column.key === 'customer'">
          <AsImage
            :src="record.customer?.image"
            alt="product image"
            class="bg-bg-light rounded-full w-10 h-10 overflow-hidden"
          />
          <span class="text-primary"
            >{{ record.customer?.name }} | {{ record.customer?.class_name }}
          </span>
          <div class="w-20 md:w-32" :title="record.customer?.specialty_name">
            <TypographyParagraph
              ellipsis
              :content="record.customer?.specialty_name"
              class="text-text"
            />
          </div>
        </template>

        <template v-else-if="column.key === 'type'">
          <TypographyText :type="record.type == 'planned' ? 'success' : 'warning'">{{
            record.type
          }}</TypographyText>
        </template>

         <template v-else-if="column.key === 'visit_date'">
                  <TypographyText type="success">{{ record.visit_date }}
                    <p style="color:blue;">  {{ record.start_time }} | {{ record.end_time }}</p>
                  </TypographyText>
                </template>

                <template v-else-if="column.key === 'actual_visit_date'">
                  <TypographyText type="success">{{ record.actual_visit_date }}
                    <p style="color:blue;">  {{ record.actual_start_visit_time }} | {{ record.actual_end_visit_time }}</p>
                  </TypographyText>
                </template>

                <template v-else-if="column.key === 'action'">
                  <div class="flex items-center gap-2">
                    <router-link :to="'/dashboard/visits/' + record.id + '/detail'">
                      <Button
                        ghost
                        type="primary"
                        size="small"
                        class="rounded shadow flex items-center gap-2"
                      >
                        <PhBook weight="duotone" :size="16" />
                      </Button>
                    </router-link>
                  </div>
                </template>
      </template>
    </Table>
    <router-link to="/dashboard/current_visits" class="text-center underline block">see more</router-link>
  </div>
</template>

<script>
import { Table, Checkbox, TypographyParagraph, TypographyText } from 'ant-design-vue';
import { PhBook } from 'phosphor-vue';

export default {
  components: {
    Table,
    Checkbox,
    TypographyText,
    TypographyParagraph,
    PhBook,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 15,
      visitColumns: [
        {
          title: '#',
          dataIndex: 'select',
          key: 'select',
        },
        {
          title: 'Account',
          dataIndex: 'account',
          key: 'account',
        },
        {
          title: 'Customer',
          dataIndex: 'customer',
          key: 'customer',
        },
        {
          title: 'User',
          dataIndex: 'user_name',
          key: 'user_name',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'visit Date',
          dataIndex: 'visit_date',
          key: 'visit_date',
        },
        {
          title: 'Actual Date',
          dataIndex: 'actual_visit_date',
          key: 'actual_visit_date',
        },
        {
          title: 'Status',
          dataIndex: 'statusAsString',
          key: 'statusAsString',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
        },
      ],
    };
  },
  watch: {},
  props: {
    currentVisits: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
