<template>
  <div v-if="user">
    <Dropdown :trigger="['click']">
      <div @click.prevent class="flex items-center gap-2 cursor-pointer">
        <img
          src="@/assets/images/user-placeholder.webp"
          alt="user image"
          class="w-8 h-8 rounded-full"
        />
        <span class="hidden md:inline-block truncate max-w-[110px]">{{ user.name }}</span>
        <ph-caret-down weight="bold" />
      </div>
      <template #overlay>
        <Menu class="border border-solid border-primary-light mt-1">
          <MenuItem class="p-0">
            <router-link
              to="/dashboard/myprofile"
              class="flex items-center gap-2 font-medium p-2 hover:opacity-70"
            >
              <PhGear :size="16" weight="duotone" />
              <span>Account</span>
            </router-link>
          </MenuItem>

          <MenuItem class="p-0" @click="this.handleLogout">
            <div class="flex items-center gap-2 font-medium p-2 hover:opacity-70">
              <PhSignOut :size="16" weight="duotone" />
              <span>Logout</span>
            </div>
          </MenuItem>
        </Menu>
      </template>
    </Dropdown>
  </div>
</template>
<script>
import { Dropdown, Menu, MenuItem } from 'ant-design-vue';

import { PhCaretDown, PhGear, PhSignOut } from 'phosphor-vue';

import { AUTH } from '../../../store/types';

export default {
  components: {
    Dropdown,

    PhCaretDown,
    PhGear,

    PhSignOut,
    Menu,
    MenuItem,
  },
  computed: {
    user() {
      return this.$store.state.auth.user || null;
    },
  },
  methods: {
    handleLogout() {
      this.$store.dispatch(AUTH.ACTION_LOGOUT);
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      this.$router.push('/login');
    },
  },
};
</script>
