import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

// APIS
const fetchNotifications = async ({ search, perPage, page }) => {
  let URL = `/notifications?page=${page}`;
  if (search) {
    URL += `&search=${search}`;
  }
  if (perPage) {
    URL += `&per_page=${perPage}`;
  }

  const { data } = await apiAxios.get(URL);
  return data;
};
const notificationResetBadge = async () => {
  const { data } = await apiAxios.get(`/notifications/badge-reset`);
  return data;
};

const useNotificationInfinQuery = ({ search, perPage, page }) =>
  useQuery(
    ['notifications', search, perPage, page],
    () => fetchNotifications({ search, perPage, page }),
    // eslint-disable-next-line function-paren-newline
  );

export default {
  fetchNotifications,
  useNotificationInfinQuery,
  notificationResetBadge,
};
