import { initializeApp } from 'firebase/app';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAYFpCWL0lvriDV4NnDmWFWJT_nrwrjvrA',
  authDomain: 'royalcrm-3d6a7.firebaseapp.com',
  projectId: 'royalcrm-3d6a7',
  storageBucket: 'royalcrm-3d6a7.appspot.com',
  messagingSenderId: '215073880339',
  appId: '1:215073880339:web:b102f270ef8c4f9dcf02e6',
  measurementId: 'G-2XSB5VHRBB',
};

const firebaseApp = initializeApp({
  // your application settings
  ...firebaseConfig,
});

// // used for the firestore refs
// export const db = getFirestore(firebaseApp);

// // here we can export reusable database references
// export const todosRef = collection(db, 'todos');

export default firebaseApp;
