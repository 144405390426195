// import { setAxiosHeader } from '@/services/apiAxios';
// import { destroyToken, saveToken } from '@/services/jwt.service';
import authApi from '@/services/apis/auth.api';
import { destroyAdminToken } from '@/services/jwt.service';
import { message } from 'ant-design-vue';
import { ADMIN_AUTH } from '../types';

export default {
  state: {
    user: null,
    userQuery: null,
  },
  getters: {},
  mutations: {
    [ADMIN_AUTH.SET_ADMIN_ACTIVE_USER](state, payload) {
      state.userQuery = payload;
    },
    [ADMIN_AUTH.SET_ADMIN_USER_DATA](state, payload) {
      state.user = payload;
    },

  },
  actions: {
    async [ADMIN_AUTH.ACTION_ADMIN_SET_USER_DATA]({ state, commit }, payload) {
      if (state.user && payload?.refetch !== true) {
        return state.user;
      }
      try {
        const data = await authApi.getUser();
        commit(ADMIN_AUTH.SET_ADMIN_USER_DATA, data);
        return data;
      } catch (error) {
        message.error(error?.response?.data?.message || error.message || 'Something went wrong');
        return { error: 'incorrect email or password' };
      }
    },
    async [ADMIN_AUTH.ACTION_ADMIN_LOGOUT]({ commit }) {
      destroyAdminToken();
      commit(ADMIN_AUTH.SET_ADMIN_USER_DATA, null);
      commit(ADMIN_AUTH.SET_ADMIN_ACTIVE_USER, null);
    },
  },

};
