import { LAYOUT } from '../types';

export default {
  state: {
    showSideMenu: false,

  },
  getters: {},
  mutations: {
    [LAYOUT.SET_TOGGOLE_SIDEMENU](state, payload) {
      state.showSideMenu = payload || false;
    },

  },
  actions: {

  },

};
