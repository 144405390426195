import { ref } from 'vue';
import NotificationApi from '@/services/apis/notifications.api';
import { useStore } from 'vuex';
import { NOTIFICATION } from '@/store/types';

export default {
  props: {
    search: {
      type: String,
      default: '',
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },

  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = NotificationApi.useNotificationInfinQuery({
      search: props.search,
      page: props.page,
      perPage: props.perPage,
    });
    if (query.value) {
      store.commit(NOTIFICATION.SET_NOTIFICATION_QUERY, query);
    }

    return { query };
  },
};
