const handleResErrors = (err, defaultError) => {
  let errorMsg = err.response?.data?.message || err.message || defaultError || "Something wen't wrong, Please Contact us";

  if (typeof errorMsg !== 'string') {
    const errArr = Object.values(errorMsg);
    if (errArr && errArr.length) {
      errorMsg = '';
      errArr.forEach((val, ix) => {
        if (ix > 0) {
          errorMsg += '\n';
        }
        // eslint-disable-next-line no-nested-ternary
        const msg = typeof val === 'string' ? val : val[0] ? val[0] : '';
        errorMsg += msg;
      });
    }
  }

  return errorMsg;
};

export default handleResErrors;
