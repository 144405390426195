import store from '@/store/index';

/* eslint-disable import/prefer-default-export */
import { setAxiosHeader } from '@/services/apiAxios';
// import { seAdminAxiosHeader } from '@/services/apiAdminAxios';
import { getToken, destroyToken } from '@/services/jwt.service';
// import { ADMIN_AUTH, AUTH } from '@/store/types';
// import apiAxios from '@/services/apiAxios';
import { AUTH } from '@/store/types';

export const pagesGurd = async (next) => {
  const token = getToken();

  console.log('pagesGurd', token);
  // next();
  if (!token) {
    next('/login');
  } else {
    setAxiosHeader(token);
    const res = await store.dispatch(AUTH.ACTION_SET_USER_DATA);
    if (res && res.email) {
      next();
    } else {
      destroyToken();
      next('/login');
    }
  }
};

export const authGurd = async (next) => {
  const token = getToken();
  console.log('authGurd', token);
  if (token) {
    next('/dashboard');
  } else {
    next();
  }
};
