import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const postLogin = async (body, notificationToken) => {
  await apiAxios.get('/sanctum/csrf-cookie');

  const bodyData = { ...body };
  if (notificationToken) {
    bodyData.DeviceToken = notificationToken;
    bodyData.DeviceType = 3;
  }

  const { data } = await apiAxios.post('/login', bodyData);
  return data;
};

const updateUser = async (body) => {
  const { data } = await apiAxios.put('/users/me', body);
  return data;
};

const getUser = async () => {
  const { data } = await apiAxios.get('/myprofile');
  return data;
};

const useUserQuery = () => useQuery(['user'], getUser);

export default {
  postLogin,
  getUser,
  updateUser,
  // querirs
  useUserQuery,
};
