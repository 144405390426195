import { PLANS } from '../types';

export default {
  state: {
    plansQuery: null,
    planvisitsQuery: null,
  },
  getters: {},
  mutations: {
    [PLANS.SET_All_PLANS_QUERY](state, payload) {
      state.plansQuery = payload;
    },
  },
  actions: {},
};
