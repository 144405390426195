<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { DASH } from '@/store/types';
import dashApi from '../../apis/dash.api';

export default {
  props: {
    lat: {
      type: Number,
      default: 1,
    },
    lng: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const store = useStore();
    const query = ref(null);
    query.value = dashApi.useFetchMapQuery({
      lat: props.lat,
      lng: props.lng,
    });
    if (query.value) {
      store.commit(DASH.SET_MAPS_QUERY, query);
    }
    return { query };
  },
};
</script>
