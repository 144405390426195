import { VISITS } from '../types';

export default {
  state: {
    visitsScheduleQuery: null,
    allvisitsQuery: null,
    currentvisitsQuery: null,
  },
  getters: {},
  mutations: {
    [VISITS.SET_VISITS_SCHEDULE_QUERY](state, payload) {
      state.visitsScheduleQuery = payload;
    },

    [VISITS.SET_ALL_VIISTS_QUERY](state, payload) {
      state.allvisitsQuery = payload;
    },

    [VISITS.SET_CURRENT_VIISTS_QUERY](state, payload) {
      state.currentvisitsQuery = payload;
    },

  },
  actions: {},
};
