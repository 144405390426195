const AUTH = {
  SET_AUTH: 'SET_AUTH',
  SET_ACTIVE_USER: 'SET_ACTIVE_USER',
  SET_USER_DATA: 'SET_USER_DATA',

  // actions
  ACTION_LOGIN: 'ACTION_LOGIN',
  ACTION_LOGOUT: 'ACTION_LOGOUT',
  ACTION_SET_USER_DATA: 'ACTION_SET_USER_DATA',
  ACTION_VALIDATE_TOKEN: 'ACTION_VALIDATE_TOKEN',

};
export default AUTH;
