<template>
  <Dropdown
    placement="bottom"
    :trigger="['click']"
    destroyPopupOnHide
    @visibleChange="handleDropdownOpened"
  >
    <Button type="link" class="px-1">
      <Badge :count="notificationsCount">
        <PhBell size="26" weight="duotone" />
      </Badge>
    </Button>
    <template #overlay>
      <Menu class="max-h-[50vh] overflow-y-auto">
        <MenuItem v-if="!allData?.length" class="w-64 overflow-hidden py-8">
          <div>
            <Empty description="No new notifications" />
          </div>
        </MenuItem>

        <MenuItem
          v-else
          class="overflow-hidden p-3"
          v-for="(item, ix) in allData"
          :key="item.title + '-new-' + ix"
        >
          <div class="w-60" v-if="item.model == 'plan'">
            <router-link :to="'/dashboard/plan/' + item.model_id + '/schedule'">
              <h3 class="whitespace-normal line-clamp-2 mb-1">{{ item.title }}</h3>
              <p class="whitespace-normal line-clamp-3 mb-1">{{ item.body }}</p>
              <p v-if="item.created_at" class="text-xs text-primary">
                {{ formattedDate(item.created_at) }}
              </p>
            </router-link>
          </div>

          <div class="w-60" v-else-if="item.model == 'visit'">
            <router-link :to="'/dashboard/visits/' + item.model_id + '/detail'">
              <h3 class="whitespace-normal line-clamp-2 mb-1">{{ item.title }}</h3>
              <p class="whitespace-normal line-clamp-3 mb-1">{{ item.body }}</p>
              <p v-if="item.created_at" class="text-xs text-primary">
                {{ formattedDate(item.created_at) }}
              </p>
            </router-link>
          </div>

          <div v-else class="w-60">
            <h3 class="whitespace-normal line-clamp-2 mb-1">{{ item.title }}</h3>
            <p class="whitespace-normal line-clamp-3 mb-1">{{ item.body }}</p>
            <p v-if="item.created_at" class="text-xs text-primary">
              {{ formattedDate(item.created_at) }}
            </p>
          </div>
        </MenuItem>

        <MenuItem class="w-64 overflow-hidden border-t border-gray-100 border-solid border-0 mt-4">
          <router-link to="/dashboard/notifications" class="text-center underline block"
            >see all notifications</router-link
          >
        </MenuItem>
      </Menu>
    </template>
  </Dropdown>
  <NotifyQuery :key="'key-' + pageNum + perPage" :page="pageNum" :perPage="perPage" />
</template>

<script>
import { Dropdown, Button, Menu, MenuItem, Badge, Empty } from 'ant-design-vue';
import { PhBell } from 'phosphor-vue';
import NotifyQuery from '@/services/queries/notifications/notifyQuery';
import { mapMutations } from 'vuex';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import notifyApi from '@/services/apis/notifications.api';
import { NOTIFICATION } from '../../../store/types';

dayjs.extend(relativeTime);
export default {
  components: { Dropdown, Button, PhBell, Menu, MenuItem, Badge, Empty, NotifyQuery },
  data() {
    return {
      pageNum: 1,
      perPage: 10,
    };
  },
  computed: {
    notifyQuery() {
      return this.$store.state.notifications.notifyQuery;
    },
    notificationData() {
      const q = this.$store.state.notifications.notifyQuery;
      if (!q || q.isLoading || q.isError) {
        return [];
      }

      return q.data?.data?.data?.data;
    },
    pushedNotifications() {
      return this.$store.state.notifications.pushedNotifications;
    },
    notificationsCount() {
      const qN = this.$store.state.notifications.notifyQuery;
      if (!qN || qN.isLoading || qN.isError) {
        return [];
      }

      const old_count = qN.data?.data?.countOfUnRead;
      return Number(old_count) + this.$store.state.notifications.newNotificationsCount;
    },
    allData() {
      return [...this.pushedNotifications, ...this.notificationData];
    },
  },
  methods: {
    ...mapMutations([NOTIFICATION.CLEAR_NOTIFICATION_COUNT]),
    handleDropdownOpened(opened) {
      if (opened) {
        notifyApi.notificationResetBadge();
        this.CLEAR_NOTIFICATION_COUNT();
      }
    },
    formattedDate(date) {
      return dayjs(date).fromNow();
    },
  },
};
</script>
