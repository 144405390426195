import authModule from './auth.module';
import layoutModule from './layout.module';
import membersModule from './members.module';
import dashModule from './dash.module';
import adminauthModule from './adminauth.module';
import usersModule from './users.module';
import rolesModule from './roles.module';
import productModule from './products.module';
import CustomerModule from './customer.module';
import VisitsModule from './visits.module';
import PlansModule from './plans.module';
import NotificationsModule from './notification.module';
import GiftModule from './gift.module';

const modules = {
  auth: authModule,
  layout: layoutModule,
  members: membersModule,
  dash: dashModule,
  adminauth: adminauthModule,
  users: usersModule,
  roles: rolesModule,
  products: productModule,
  customers: CustomerModule,
  visits: VisitsModule,
  plans: PlansModule,
  notifications: NotificationsModule,
  gifts: GiftModule,
};

export default modules;
