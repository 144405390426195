<template>
  <div
    class="main-card bg-bg-light shadow flex-1 min-w-[12rem] rounded p-3 lg:p-4 flex gap-4 lg:gap-6 items-center justify-between"
  >
    <div class="flex flex-col gap-2 flex-shrink-0">
      <small class=" whitespace-nowrap inline-block">{{title}}</small>
      <strong class="text-lg lg:text-xl text-text-dark">{{value}}</strong>
    </div>
    <div
      class="bg-gradient-to-br from-primary to-[#F5F7DC] w-12 h-12 text-white rounded-lg flex items-center justify-center flex-shrink-0"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  watch: {},
  props: {
    title: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>
