import { GIFTS } from '../types';

export default {
  state: {
    giftQuery: null,
  },
  getters: {},
  mutations: {
    [GIFTS.SET_GIFT_QUERY](state, payload) {
      state.giftQuery = payload;
    },
  },
  actions: {},
};
