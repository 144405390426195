import { useQuery } from 'vue-query';
import apiAxios from '../apiAxios';

const fetchDashStats = async () => {
  const { data } = await apiAxios.get('/dashboard-stats');
  return data;
};

const fetchLogs = async ({ search, perPage, page }) => {
  let URL = `/logs?page=${page}`;
  if (search) {
    URL += `&search=${search}`;
  }
  if (perPage) {
    URL += `&per_page=${perPage}`;
  }

  const { data } = await apiAxios.get(URL);
  return data;
};

const getVisitCharts = async (body) => {
  const { data } = await apiAxios.post('/visits/visit-charts', body);
  return data;
};

const fetchMaps = async ({ lat, lng }) => {
  const { data } = await apiAxios.post('/maps', { lat, lng });
  return data;
};

const fetchSetting = async () => {
  const { data } = await apiAxios.get(`/setting`);
  return data;
};

const addSetting = async (body) => {
  const { data } = await apiAxios.post('/setting', body);
  return data;
};

// queries
const useDashStatsQuery = () => useQuery(['dashboard-stats'], fetchDashStats);
const useFetchMapQuery = ({ lat, lng }) =>
  useQuery(['maps', lat, lng], () => fetchMaps({ lat, lng }));

const useLogsInfinQuery = ({ search, perPage, page }) =>
  useQuery(['logs', search, perPage, page], () => fetchLogs({ search, perPage, page }));

const useSettingQuery = () => useQuery(['settings'], fetchSetting);

export default {
  fetchDashStats,
  // queries
  useDashStatsQuery,
  useLogsInfinQuery,
  getVisitCharts,
  fetchMaps,
  useFetchMapQuery,
  useSettingQuery,
  addSetting,
};
