<template>
  <div>HOME</div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {},
};
</script>
