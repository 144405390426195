import { DASH } from '../types';

export default {
  state: {
    dashStatsQuery: null,
    logsQuery: null,
    mapsQuery: null,
    settingQuery: null,
  },
  mutations: {
    [DASH.SET_DASH_STATS_QUERY](state, payload) {
      state.dashStatsQuery = payload;
    },
    [DASH.SET_LOGS_QUERY](state, payload) {
      state.logsQuery = payload;
    },
    [DASH.SET_MAPS_QUERY](state, payload) {
      state.mapsQuery = payload;
    },
    [DASH.SET_SETTINGS_QUERY](state, payload) {
      state.settingQuery = payload;
    },
  },
  actions: {},
};
