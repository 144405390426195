<template>
  <form-item
    :name="name"
    class=" m-0"
    :class="`${error ? ' ant-form-item-has-error ' : ''} ${label && !label.trim() ? ' text-opacity-0' : ''} ` "
    labelAlign="left"
  >
    <template v-if="label" #label>
      <div class="flex items-center">
        <span>{{label}}</span>
        <span v-if="required" class="text-red-600 text-lg">*</span>

        <Tooltip v-if="labelTooltip" class="ml-1">
          <template #title>{{labelTooltip}}</template>
          <PhWarningCircle weight="duotone" class="text-primary" size="16" />
        </Tooltip>
      </div>
    </template>

    <slot />
    <small v-if="error && !hideErrors" class="text-red-500 text-sm mt-2 block">{{error}}</small>
  </form-item>
</template>
<script>
import { FormItem, Tooltip } from 'ant-design-vue';
import { PhWarningCircle } from 'phosphor-vue';

// :label="label ? `${label}${required ? ' *' : ''}`: null"
export default {
  components: { FormItem, Tooltip, PhWarningCircle },
  props: {
    name: String,
    label: String,
    labelTooltip: String,
    required: {
      type: Boolean,
      default: false,
    },
    hideErrors: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
};
</script>
