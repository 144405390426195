<template>
  <AuthLayout>
    <LoginMain />
  </AuthLayout>
</template>

<script>
// @ is an alias to /src
import AuthLayout from '@/layouts/AuthLayout.vue';
import LoginMain from '@/components/auth/LoginMain.vue';

export default {
  components: {
    AuthLayout,
    LoginMain,
  },
};
</script>
