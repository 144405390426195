import { PRODUCTS } from '../types';

export default {
  state: {
    specialtyQuery: null,
    productQuery: null,
    productnoteQuery: null,
    companyQuery: null,
    categoryQuery: null,
  },
  getters: {},
  mutations: {
    [PRODUCTS.SET_SPECIALTY_QUERY](state, payload) {
      state.specialtyQuery = payload;
    },

    [PRODUCTS.SET_PRODUCT_QUERY](state, payload) {
      state.productQuery = payload;
    },

    [PRODUCTS.SET_PRODUCT_NOTES_QUERY](state, payload) {
      state.productnoteQuery = payload;
    },

    [PRODUCTS.SET_COMPANY_QUERY](state, payload) {
      state.companyQuery = payload;
    },
    [PRODUCTS.SET_CATEGORY_QUERY](state, payload) {
      state.categoryQuery = payload;
    },
  },
  actions: {},
};
