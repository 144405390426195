import { USERS } from '../types';

export default {
  state: {
    usersQuery: null,
    usersInfiniteQuery: null,
    userpositionQuery: null,
    positionQuery: null,
  },
  getters: {},
  mutations: {
    [USERS.SET_USERS_QUERY](state, payload) {
      state.usersQuery = payload;
    },
    [USERS.SET_USERS_INFINITE_QUERY](state, payload) {
      state.usersInfiniteQuery = payload;
    },
    [USERS.SET_USER_POSITIONS_QUERY](state, payload) {
      state.userpositionQuery = payload;
    },
    [USERS.SET_POSITIONS_QUERY](state, payload) {
      state.positionQuery = payload;
    },
  },
  actions: {},
};
