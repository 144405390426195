/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';
import { authGurd, pagesGurd } from '@/middleware/gurds';

import HomeRouter from '@/views/HomeRouter.vue';
import HomeView from '@/views/HomeView.vue';

// dashboard
import DashboardRouter from '@/views/dashboard/DashboardRouter.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';

// Auth
import AuthRouter from '@/views/auth/AuthRouter.vue';
import LoginView from '@/views/auth/LoginView.vue';

const routes = [
  {
    path: '/',
    component: HomeRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      pagesGurd(next);
    },
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        redirect: '/dashboard',
      },
    ],
  },

  {
    path: '/dashboard',
    component: DashboardRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      pagesGurd(next);
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: DashboardView,
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/dashboard/users/UsersView.vue'),
      },
      {
        path: 'admins',
        name: 'admins',
        component: () => import('@/views/dashboard/users/AdminsView.vue'),
      },
      {
        path: 'users/add_user',
        name: 'add User',
        component: () => import('@/views/dashboard/users/AddUserView.vue'),
      },
      {
        path: 'users/:userId/edit',
        name: 'Edit User',
        component: () => import('@/views/dashboard/users/EditUserView.vue'),
      },
      {
        path: 'users/:userId/detail',
        name: 'Single User',
        component: () => import('@/views/dashboard/users/SingleUserView.vue'),
      },
      {
        path: 'myprofile',
        name: 'User Profile',
        component: () => import('@/views/dashboard/users/ProfileView.vue'),
      },
      {
        path: 'roles',
        name: 'roles',
        component: () => import('@/views/dashboard/roles/RoleView.vue'),
      },
      {
        path: 'roles/add_role',
        name: 'add Role',
        component: () => import('@/views/dashboard/roles/AddRoleView.vue'),
      },
      {
        path: 'roles/:roleId/edit',
        name: 'Edit Role',
        component: () => import('@/views/dashboard/roles/EditRoleView.vue'),
      },
      {
        path: 'specialty',
        name: 'Specialty',
        component: () => import('@/views/dashboard/specialty/SpecialtyView.vue'),
      },
      {
        path: 'products',
        name: 'Products',
        component: () => import('@/views/dashboard/products/ProductView.vue'),
      },
      {
        path: 'product/:productId/detail',
        name: 'Product Detail',
        component: () => import('@/views/dashboard/products/SingleProductView.vue'),
      },
      {
        path: 'classes',
        name: 'Classes',
        component: () => import('@/views/dashboard/classes/ClassView.vue'),
      },
      {
        path: 'bricks',
        name: 'Bricks',
        component: () => import('@/views/dashboard/bricks/BricksView.vue'),
      },
      {
        path: 'acc_type',
        name: 'Account Type',
        component: () => import('@/views/dashboard/customer/AccountTypeView.vue'),
      },
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import('@/views/dashboard/accounts/AccountView.vue'),
      },
      {
        path: 'account/:accountId/detail',
        name: 'Account Detail',
        component: () => import('@/views/dashboard/accounts/SingleAccountView.vue'),
      },
      {
        path: 'doctors',
        name: 'Doctors',
        component: () => import('@/views/dashboard/doctors/DoctorView.vue'),
      },
      {
        path: 'all_plans',
        name: 'ALL Plans',
        component: () => import('@/views/dashboard/reports/plans/PlanIndexView.vue'),
      },
      {
        path: 'plan/:planId/detail',
        name: 'Single Plan',
        component: () => import('@/views/dashboard/reports/plans/SingleplanView.vue'),
      },
      {
        path: 'plan/:planId/schedule',
        name: 'Plans',
        component: () => import('@/views/dashboard/reports/plans/planScheduleView.vue'),
      },
      {
        path: 'visits/:visitId/detail',
        name: 'Visit Detail',
        component: () => import('@/views/dashboard/reports/plans/VisitDetailView.vue'),
      },
      {
        path: 'visits',
        name: 'Visits',
        component: () => import('@/views/dashboard/reports/visits/VisitView.vue'),
      },

      {
        path: 'user_visit/:userId/detail',
        name: 'User Visits',
        component: () => import('@/views/dashboard/reports/visits/UserVisitsView.vue'),
      },

      {
        path: 'maps',
        name: 'Maps',
        component: () => import('@/views/dashboard/reports/maps/MapView.vue'),
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/dashboard/notifications/NotificationView.vue'),
      },
      {
        path: 'logs',
        name: 'All Logs',
        component: () => import('@/views/dashboard/logs/LogView.vue'),
      },
      {
        path: 'gifts',
        name: 'All Gifts',
        component: () => import('@/views/dashboard/gifts/GiftView.vue'),
      },
      {
        path: 'setting',
        name: 'setting',
        component: () => import('@/views/dashboard/settings/SettingView.vue'),
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import('@/views/dashboard/company/CompanyView.vue'),
      },
      {
        path: 'pharmacy',
        name: 'Pharmacy',
        component: () => import('@/views/dashboard/pharmacy/PharmacyView.vue'),
      },
      {
        path: 'pharmacygroup',
        name: 'PharmacyGroup',
        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/dashboard/pharmacy/PharmacyGroupView.vue'),
      },
      {
        path: 'category',
        name: 'Category',
        component: () => import('@/views/dashboard/category/CategoryView.vue'),
      }, {
        path: 'current_visits',
        name: 'Current Visits',
        // eslint-disable-next-line import/no-unresolved
        component: () => import('@/views/dashboard/reports/visits/CurrentVisitView.vue'),
      },
      {
        path: 'positions',
        name: 'Positions',
        component: () => import('@/views/dashboard/positions/PositionView.vue'),
      },
    ],
  },

  // {
  //   path: '/admin',
  //   component: AdminRouterVue,
  //   beforeEnter: (to, from, next) => {
  //     // gurds
  //     // AdminPagesGurd(next);
  //   },
  //   children: [
  //     {
  //       path: '',
  //       name: 'admin',
  //       component: AdminView,
  //     },
  //     {
  //       path: 'business-management',
  //       name: 'business-management',
  //       component: BusinessManagementView,
  //     },

  //   ],
  // },

  // {
  //   path: '/admin',
  //   component: AdminAuthRouter,
  //   beforeEnter: (to, from, next) => {
  //     // gurds
  //     // pagesGurd(next);
  //     // adminAuthGurd(next);
  //   },
  //   children: [
  //     {
  //       path: 'login',
  //       name: 'admin-login',
  //       component: AdminLoginView,
  //     },
  //   ],
  // },

  {
    path: '/',
    component: AuthRouter,
    beforeEnter: (to, from, next) => {
      // gurds
      authGurd(next);
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: LoginView,
      },
    ],
  },

  // {
  //   path: '*',
  //   name: 'not-found',
  //   redirect: '/dashboard',
  // },

  // {
  //   path: '/auth',
  //   name: 'auth',
  //   redirect: '/auth/login',
  //   component: AuthView,
  //   beforeEnter: (to, from, next) => {
  //     authGurd(next);
  //   },
  //   children: [
  //     {
  //       path: 'login',
  //       name: 'login',
  //       component: LoginView,
  //     },
  //     {
  //       path: '*',
  //       redirect: '/auth/login',
  //     },
  //   ],
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
