import firebaseApp from '@/services/firebase.config';
import CONFIG from '@/services/config';

import { onMessage, getToken, getMessaging } from 'firebase/messaging';
import { NOTIFICATION } from '../types';

const vapidKey = CONFIG.VAPID_KEY;
export default {
  state: {
    notifyQuery: null,
    notification_token: null,
    pushedNotifications: [],
    newNotificationsCount: 0,
  },
  getters: {},
  mutations: {
    [NOTIFICATION.SET_NOTIFICATION_QUERY](state, payload) {
      state.notifyQuery = payload;
    },
    [NOTIFICATION.SET_NOTIFICATION_TOKEN](state, payload) {
      state.notification_token = payload;
    },
    [NOTIFICATION.SET_NEW_NOTIFICATION](state, payload) {
      state.pushedNotifications = [payload, ...state.pushedNotifications];
      state.newNotificationsCount += 1;
    },
    [NOTIFICATION.CLEAR_NOTIFICATION_COUNT](state) {
      state.newNotificationsCount = 0;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async [NOTIFICATION.REGISTER_NOTIFICATION_SERVICE_ACTION]({ commit }) {
      console.log('REGISTER_NOTIFICATION_SERVICE_ACTION');
      try {
        if (!('serviceWorker' in navigator)) {
          console.log('No service worker available');
          return;
        }
        const messaging = await getMessaging(firebaseApp);
        onMessage(messaging, (msg) => {
          //   {
          //     "from": "390275127706",
          //     "messageId": "14fb62c8-f3f0-43c3-b227-89f85dd8a4bc",
          //     "data": {
          //         "model": "plan",
          //         "body": "dssssssssssnew of Your Notification in Data",
          //         "title": "sdddddddddddddddddddddddnew  Notification in Title"
          //     }
          // }

          console.log(msg);
          if (msg.data) {
            commit(NOTIFICATION.SET_NEW_NOTIFICATION, msg.data);
          }
        });

        const currentToken = await getToken(messaging, {
          vapidKey,
        });
        if (currentToken) {
          console.log(currentToken);
          commit(NOTIFICATION.SET_NOTIFICATION_TOKEN, currentToken);
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      } catch (error) {
        console.log('NOTIFICATION CONFIG error', error);
      }
    },
  },
};
