<template>
  <div class="w-full md:w-2/3 lg:w-1/2 bg-bg shadow-md rounded-md mx-auto">
    <h3
      v-if="title"
      class="py-2 font-normal text-center text-white bg-gradient-to-b from-primary to-primary-light rounded-t-md"
    >{{ title }}</h3>
    <div class="py-6 lg:py-8 px-8 lg:px-10">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
