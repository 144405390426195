<template>
  <div>
    <div class="flex justify-between">
      <h2 class="text-xl">Top 3 Performers</h2>

      <div style="
    display: inline-flex;
">
        <CustumSelect
                name="dateFormat"
                placeholder="Format"
                :data="FormatData"
                @setInput="setInput"
                />

        <date-picker
          :picker="datePicker"
          v-model="search_date"
          :format="dateFormate"
          @change="handleChange"
          :allowClear="true"
        />
      </div>
    </div>

    <div v-if="isLoading">
      <SkeletonInput active class="!w-full" v-for="x in 10" :key="x" />
    </div>

    <div v-if="!isLoading" class="mt-6">
      <BarChart :chartData="chartData" isHorizontal />
    </div>
  </div>
</template>

<script>
import { DatePicker, SkeletonInput } from 'ant-design-vue';
import DashAPI from '@/services/apis/dash.api';
import dayjs from 'dayjs';
import CustumSelect from '@/components/shared/form/CustumSelect.vue';
import BarChart from './BarChart.vue';

export default {
  components: {
    DatePicker,
    BarChart,
    SkeletonInput,
    CustumSelect,
  },
  data() {
    return {
      visitcharts: null,
      isLoading: true,
      search_date: null,
      dateFormat: 'YYYY-MM', // Default format
      FormatData: [
        {
          id: 'YYYY-MM',
          name: 'Month',
        },
        {
          id: 'YYYY-MM-DD',
          name: 'Day',
        },
        {
          id: 'YYYY',
          name: 'Year',
        },
      ],
    };
  },
  watch: {},
  props: {},
  computed: {
    chartData() {
      const chartData = {
        labels: this.visitcharts?.map((item) => item.name),
        datasets: [
          {
            label: 'Visits',
            data: this.visitcharts?.map((item) => item.visit_count),
            backgroundColor: '#781317',
            borderColor: '#781317',
            borderWidth: 1,
          },
        ],
      };
      return chartData;
    },
    dateFormate() {
      return this.search_date != null ? dayjs(this.search_date).format(this.dateFormat) : null;
    //  return this.selectedDate ? moment(this.selectedDate).format(this.dateFormat) : '';
    //  return 'MMMM YYYY';
    },
    allvisitsQuery() {
      return this.$store.state.visits.allvisitsQuery;
    },
    datePicker() {
      let QD = 'month';
      // eslint-disable-next-line eqeqeq, no-const-assign
      if (this.dateFormat == 'YYYY-MM-DD') QD = 'day';
      // eslint-disable-next-line eqeqeq
      else if (this.dateFormat == 'YYYY-MM') QD = 'month';
      // eslint-disable-next-line eqeqeq
      else if (this.dateFormat == 'YYYY') QD = 'year';
      return QD;
    },
  },
  methods: {
    handleChange(date) {
      this.search_date = date ? dayjs(date).format(this.dateFormat) : null;
      this.displayCharts();
      if (this.allvisitsQuery?.refetch) {
        this.allvisitsQuery?.refetch();
      }
    },
    async displayCharts() {
      const body = { search_date: this.search_date, date_format: this.dateFormat };
      const RespData = await DashAPI.getVisitCharts(body);
      this.visitcharts = RespData?.data;
      this.isLoading = false;
    },
    setInput(vals) {
      this.dateFormat = vals.dateFormat.value;
    },
  },
  created() {
    this.displayCharts();
  },
};
</script>

<style lang="scss" scoped></style>
