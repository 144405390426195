<template>
  <div>
    <div v-if="statisticsData" class="flex flex-wrap items-center gap-5 lg:gap-8">

      <router-link to="/dashboard/admins" class="text-center  block">
        <SingleStatistic title="Admins" :value="statisticsData?.total_users">
            <PhUsers weight="duotone" size="28" />
        </SingleStatistic>
        </router-link>

      <router-link to="/dashboard/users" class="text-center  block">
      <SingleStatistic title="MedicalRep" :value="statisticsData?.total_medicalrep">
        <PhBriefcase weight="duotone" size="28" />
      </SingleStatistic>
        </router-link>

       <router-link to="/dashboard/products" class="text-center  block">
      <SingleStatistic title="Total Products" :value="statisticsData?.total_products">
        <PhCube weight="duotone" size="28" />
      </SingleStatistic>
       </router-link>

        <router-link to="/dashboard/all_plans" class="text-center  block">
      <SingleStatistic title="Current Plans" :value="statisticsData?.total_current_plans">
        <PhSquaresFour weight="duotone" size="28" />
      </SingleStatistic>
        </router-link>

      <router-link to="/dashboard/current_visits" class="text-center  block">
      <SingleStatistic title="Current Visits" :value="statisticsData?.total_current_visits">
        <PhBuildings weight="duotone" size="28" />
      </SingleStatistic>
      </router-link>
    </div>
  </div>
</template>

<script>
import { PhUsers, PhBriefcase, PhCube, PhSquaresFour, PhBuildings } from 'phosphor-vue';

import SingleStatistic from './SingleStatistic.vue';

export default {
  components: {
    SingleStatistic,
    PhUsers,
    PhBriefcase,
    PhCube,
    PhSquaresFour,
    PhBuildings,
  },
  data() {
    return {};
  },
  watch: {},
  props: {
    statisticsData: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
