<template>
  <div class="w-full border-t border-gray-100 border-solid border-0 mt-4 pt-3">
    <Table :dataSource="currentPlans" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <!-- <template v-if="column.key === 'select'"> <Checkbox /> </template> -->
        <template v-if="column.key === 'plan_code'">
          <router-link :to="'/dashboard/plan/' + record.id + '/schedule'">
            <span> plan{{ record.plan_code }}</span>
          </router-link>
        </template>
         <template v-else-if="column.key === 'created_at'">
          <TypographyText keyboard type="success"
            >{{ record.start_date }}|{{ record.end_date }}
          </TypographyText>
        </template>
      </template>
    </Table>
    <router-link to="/dashboard/all_plans" class="text-center underline block"
      >see more</router-link
    >
  </div>
</template>

<script>
import { Table, TypographyText } from 'ant-design-vue';

export default {
  components: {
    Table,
    TypographyText,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 15,
      columns: [
        {
          title: 'Plan',
          dataIndex: 'plan_code',
          key: 'plan_code',
        },
        {
          title: 'User',
          dataIndex: 'user_name',
          key: 'user_name',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
        },
      ],
    };
  },
  watch: {},
  props: {
    currentPlans: {
      type: Object,
      default: null,
    },
  },
  computed: {},
  methods: {},
  created() {
    console.log(this.currentPlans);
  },
};
</script>

<style lang="scss" scoped></style>
